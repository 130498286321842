import { createTheme, darken } from '@mui/material/styles';

const akhetGrey = '#EDF2F4';
const akhetBlue = '#2B2D42';
const akhetRed = '#EF233C';

const tonalOffset = 0.1;

const theme = createTheme({
  palette: {
    common: {
      blue: akhetBlue,
      orange: akhetRed,
    },
    primary: {
      main: akhetGrey,
    },
    secondary: {
      main: akhetBlue,
    },
    text: {
      primary: akhetGrey,
      secondary: akhetRed,
    },
  },
  typography: {
    allVariants: {
      fontFamily: '"Inter", sans-serif',
    },
    tab: {
      fontWeight: 700,
      fontSize: '1rem',
    },
    h1: {
      fontWeight: 700,
      fontSize: '4rem',
      '@media (max-width:960px)': {
        fontSize: '4rem',
      },
    },
    h2: {
      fontWeight: 700,
      fontSize: '2.5rem',
      color: akhetBlue,
      lineHeight: 1.5,
    },
    h3: {
      fontSize: '2.5rem',
      color: akhetBlue,
      fontWeight: 600,
    },
    h4: {
      fontSize: '1.7rem',
      fontWeight: 700,
      color: akhetBlue,
    },
    h5: {
      fontSize: '1.5rem',
      lineHeight: '1.75rem',
      fontWeight: 700,
    },
    h6: {
      fontWeight: 500,
      color: akhetBlue,
    },
    estimate: {
      color: 'white',
      fontWeight: 700,
      textTransform: 'none',
    },
    subtitle1: {
      fontSize: '1.25rem',
      fontWeight: 300,
      color: akhetGrey,
    },
    subtitle2: {
      color: 'white',
      fontSize: '1rem',
      fontWeight: 300,
    },
    body1: {
      fontSize: '1.25rem',
      color: akhetGrey,
      fontWeight: 300,
    },
    learnMoreButton: {
      borderColor: akhetBlue,
      color: akhetBlue,
      backgroundColor: akhetGrey,
      borderStyle: 'solid',
      borderWidth: 1,
      '&:hover': {
        backgroundColor: darken(akhetGrey, tonalOffset),
      },
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '@global': {
          html: {
            body: {
              fontFamily: '"Inter", sans-serif',
              fontWeight: 400,
              backgroundColor: akhetBlue,
            },
            WebkitFontSmoothing: 'auto',
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: akhetBlue,
          fontSize: '1rem',
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          color: akhetGrey,
          fontWeight: 300,
        },
        underline: {
          '&:before': {
            borderBottom: `2px solid ${akhetBlue}`,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 50,
          fontWeight: 700,
          fontSize: '1rem',
          height: 45,
          textTransform: 'none',
        },
        containedPrimary: {
          backgroundColor: akhetRed,
          color: akhetGrey,
          '&:hover': {
            backgroundColor: darken(akhetRed, tonalOffset),
          },
        },
        outlinedSecondary: {
          borderColor: akhetBlue,
          backgroundColor: akhetGrey,
          color: akhetBlue,
          '&:hover': {
            backgroundColor: darken(akhetGrey, tonalOffset),
          },
        },
      },
    },
  },
});

export { theme };