import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Grid from '@mui/material/Grid';
import Toolbar from '@mui/material/Toolbar';
import {
  Button, Typography, useMediaQuery, useTheme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';
import logo from '../../assets/logo.svg';
import { ElevationScroll } from './ElevationScroll';
import { WebNav } from './WebNav';
import { MobileNav } from './MobileNav';

const useStyles = makeStyles((theme) => ({
  logo: {
    height: '3rem',
    [theme.breakpoints.down('lg')]: {
      height: '3em',
    },
    [theme.breakpoints.down('sm')]: {
      height: '2.5em',
    },
  },
  logoContainer: {
    '&:hover': {
      background: 'transparent',
    },
  },
  appBar: {
    zIndex: theme.zIndex.modal + 1,
    backgroundColor: theme.palette.secondary.main,
    paddingRight: theme.spacing(10),
    height: '5rem',
    padding: '1rem 5rem',
    [theme.breakpoints.down('lg')]: {
      height: '4.5rem',
      padding: '1rem 2rem',
    },
    [theme.breakpoints.down('sm')]: {
      height: '4rem',
      padding: '0.5rem 1rem',
    },
  },
  toolbarMargin: {
    height: '5rem',
    [theme.breakpoints.down('lg')]: {
      height: '4.5rem',
    },
    [theme.breakpoints.down('md')]: {
      height: '4rem',
    },
  },
}));

export function Header() {
  const classes = useStyles();
  const theme = useTheme();
  const matchesMd = useMediaQuery(theme.breakpoints.down('lg'));
  const matchesSm = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <>
      <ElevationScroll>
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar disableGutters variant="dense">
            <Button component={Link} to="/" className={classes.logoContainer} disableRipple>
              <img src={logo} alt="company logo" className={classes.logo} />
              <Grid container direction="column">
                <Typography variant="h5">Akhet Digital</Typography>
                {!matchesSm && <Typography variant="subtitle2" className={classes.mottoSubtitle}>Your SAP commerce solution partner</Typography>}
              </Grid>
            </Button>
            {matchesMd ? <MobileNav /> : <WebNav />}
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      <div className={classes.toolbarMargin} />
    </>
  );
}
