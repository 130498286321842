import * as React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import StyledEngineProvider from "@mui/material/StyledEngineProvider";
import { CssBaseline } from '@mui/material';
import { Header } from './ui/Header';
import { theme } from '../theme';
import { flatRoutes } from '../routes';
import { Footer } from './ui/Footer';

function App() {
  return (
    <StyledEngineProvider injectFirst>
      (<ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          <Header />
          <Routes>
            {flatRoutes.map((route) => (
                <Route path={route.path} element={route.element} key={route.label} />
            ))}
          </Routes>
          <Footer />
        </BrowserRouter>
      </ThemeProvider>)
    </StyledEngineProvider>
  );
}

export default App;
