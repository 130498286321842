import React from 'react';
import { makeStyles } from '@mui/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: '100%',
    color: theme.palette.text.hint,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
    '&.selected': {
      backgroundColor: theme.palette.primary.light,
    },
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
}));

export function SelectableCard({
  title, icon, iconAlt, id, onClick, selected,
}) {
  const classes = useStyles();

  return (
    <Card className={`${classes.root} ${selected ? 'selected' : ''}`} onClick={() => onClick(id)}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          {title}
        </Typography>
        <img src={icon} alt={iconAlt} height={100} width={100} />
      </CardContent>
    </Card>
  );
}
