import customSoftwareLogo from '../assets/custom_software.svg';
import webAppDevelopmentLogo from '../assets/webAppDevelopment.svg';

export const estimateQuestions = [
  {
    name: 'Major',
    title: 'Project Type',
    subtitle: 'Whatever',
    id: 200,
    options: [
      {
        id: 201,
        title: 'Web App',
        subtitle: 'Test',
        icon: customSoftwareLogo,
        iconAlt: 'alt test',
      },
      {
        id: 202,
        title: 'Mobile App',
        subtitle: 'Test',
        icon: customSoftwareLogo,
        iconAlt: 'alt test',
      },
      {
        id: 203,
        title: 'E-Commerce Solution',
        subtitle: 'Test',
        icon: customSoftwareLogo,
        iconAlt: 'alt test',
      },
      {
        id: 204,
        title: 'Custom software',
        subtitle: 'Test',
        icon: customSoftwareLogo,
        iconAlt: 'alt test',
      },
    ],
  },
  {
    name: 'Medium',
    title: 'Major',
    subtitle: 'Whatever',
    id: 300,
    options: [
      {
        id: 301,
        title: 'Photo/Video',
        subtitle: null,
        icon: webAppDevelopmentLogo,
        iconAlt: 'camera outline',
        selected: false,
        cost: 25,
      },
      {
        id: 302,
        title: 'GPS',
        subtitle: null,
        icon: webAppDevelopmentLogo,
        iconAlt: 'gps pin',
        selected: false,
        cost: 25,
      },
      {
        id: 303,
        title: 'File Transfer',
        subtitle: null,
        icon: webAppDevelopmentLogo,
        iconAlt: 'outline of cloud with arrow pointing up',
        selected: false,
        cost: 25,
      },

    ],
  },
  {
    name: 'Minor',
    id: 400,
    options: [
      {
        id: 401,
        title: 'Users/Authentication',
        subtitle: null,
        icon: webAppDevelopmentLogo,
        iconAlt: 'outline of a person with a plus sign',
        cost: 25,
      },
      {
        id: 402,
        title: 'Biometrics',
        subtitle: null,
        icon: webAppDevelopmentLogo,
        iconAlt: 'fingerprint',
      },
      {
        id: 403,
        title: 'Push Notifications',
        subtitle: null,
        icon: webAppDevelopmentLogo,
        iconAlt: 'outline of a bell',
      },
    ],
  },
];

// Project Type:

//  Web App
//  Mobile App
//  Cross-Platform App
//  E-commerce Solution
//  Other (please specify)

// const softwareQuestions = [
//   { ...defaultQuestions[0], active: false },
//   {
//     id: 2,
//     title: "Which platforms do you need supported?",
//     subtitle: "Select all that apply.",
//     options: [
//       {
//         id: 1,
//         title: "Web Application",
//         subtitle: null,
//         icon: webAppDevelopmentLogo,
//         iconAlt: "computer outline",
//         selected: false,
//         cost: 100
//       },
//       {
//         id: 2,
//         title: "iOS Application",
//         subtitle: null,
//         icon: webAppDevelopmentLogo,
//         iconAlt: "outline of iphone",
//         selected: false,
//         cost: 100
//       },
//       {
//         id: 3,
//         title: "Android Application",
//         subtitle: null,
//         icon: webAppDevelopmentLogo,
//         iconAlt: "outlines of android phone",
//         selected: false,
//         cost: 100
//       }
//     ],
//     active: true
//   },
//   {
//     id: 3,
//     title: "Which features do you expect to use?",
//     subtitle: "Select all that apply.",
//     options: [
//       {
//         id: 1,
//         title: "Photo/Video",
//         subtitle: null,
//         icon: webAppDevelopmentLogo,
//         iconAlt: "camera outline",
//         selected: false,
//         cost: 25
//       },
//       {
//         id: 2,
//         title: "GPS",
//         subtitle: null,
//         icon: webAppDevelopmentLogo,
//         iconAlt: "gps pin",
//         selected: false,
//         cost: 25
//       },
//       {
//         id: 3,
//         title: "File Transfer",
//         subtitle: null,
//         icon: webAppDevelopmentLogo,
//         iconAlt: "outline of cloud with arrow pointing up",
//         selected: false,
//         cost: 25
//       }
//     ],
//     active: false
//   },
//   {
//     id: 4,
//     title: "Which features do you expect to use?",
//     subtitle: "Select all that apply.",
//     options: [
//       {
//         id: 1,
//         title: "Users/Authentication",
//         subtitle: null,
//         icon: webAppDevelopmentLogo,
//         iconAlt: "outline of a person with a plus sign",
//         selected: false,
//         cost: 25
//       },
//       {
//         id: 2,
//         title: "Biometrics",
//         subtitle: null,
//         icon: webAppDevelopmentLogo,
//         iconAlt: "fingerprint",
//         selected: false,
//         cost: 25
//       },
//       {
//         id: 3,
//         title: "Push Notifications",
//         subtitle: null,
//         icon: webAppDevelopmentLogo,
//         iconAlt: "outline of a bell",
//         selected: false,
//         cost: 25
//       }
//     ],
//     active: false
//   },
//   {
//     id: 5,
//     title: "What type of custom features do you expect to need?",
//     subtitle: "Select one.",
//     options: [
//       {
//         id: 1,
//         title: "Low Complexity",
//         subtitle: "(Informational)",
//         icon: webAppDevelopmentLogo,
//         iconAlt: "'i' inside a circle",
//         selected: false,
//         cost: 25
//       },
//       {
//         id: 2,
//         title: "Medium Complexity",
//         subtitle: "(Interactive, Customizable, Realtime)",
//         icon: webAppDevelopmentLogo,
//         iconAlt: "two toggle switches",
//         selected: false,
//         cost: 50
//       },
//       {
//         id: 3,
//         title: "High Complexity",
//         subtitle: "(Data Modeling and Computation)",
//         icon: webAppDevelopmentLogo,
//         iconAlt: "outline of line graph",
//         selected: false,
//         cost: 100
//       }
//     ],
//     active: false
//   },
//   {
//     id: 6,
//     title: "How many users do you expect?",
//     subtitle: "Select one.",
//     options: [
//       {
//         id: 1,
//         title: "0-10",
//         subtitle: null,
//         icon: webAppDevelopmentLogo,
//         iconAlt: "person outline",
//         selected: false,
//         cost: 1
//       },
//       {
//         id: 2,
//         title: "10-100",
//         subtitle: null,
//         icon: webAppDevelopmentLogo,
//         iconAlt: "outline of two people",
//         selected: false,
//         cost: 1.25
//       },
//       {
//         id: 3,
//         title: "100+",
//         subtitle: null,
//         icon: webAppDevelopmentLogo,
//         iconAlt: "outline of three people",
//         selected: false,
//         cost: 1.5
//       }
//     ],
//     active: false
//   }
// ];

// const websiteQuestions = [
//   { ...defaultQuestions[0], active: false },
//   {
//     id: 2,
//     title: "Which type of website are you wanting?",
//     subtitle: "Select one.",
//     options: [
//       {
//         id: 1,
//         title: "Basic",
//         subtitle: "(Informational)",
//         icon: webAppDevelopmentLogo,
//         iconAlt: "person outline",
//         selected: false,
//         cost: 100
//       },
//       {
//         id: 2,
//         title: "Interactive",
//         subtitle: "(Users, API's, Messaging)",
//         icon: webAppDevelopmentLogo,
//         iconAlt: "outline of two people",
//         selected: false,
//         cost: 200
//       },
//       {
//         id: 3,
//         title: "E-Commerce",
//         subtitle: "(Sales)",
//         icon: webAppDevelopmentLogo,
//         iconAlt: "outline of three people",
//         selected: false,
//         cost: 250
//       }
//     ],
//     active: true
//   }
// ];
