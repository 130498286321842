import * as React from 'react';
import Grid from '@mui/material/Grid';
import {makeStyles} from '@mui/styles';
import {keyBy} from 'lodash';
import customSoftwareLogo from '../assets/custom_software.svg';
import mobileDevelopmentLogo from '../assets/mobileDevelopment.svg';
import webAppDevelopmentLogo from '../assets/webAppDevelopment.svg';
import {SelectableCard} from './ui/SelectableCards';
import AlternativeStepper from './ui/Stepper';
import {useStepper} from '../hooks/useStepper';
import {estimateQuestions} from '../constants/estimateQuestions';

const useStyles = makeStyles((theme) => ({
  mobileStepper: {
    root: {
      maxWidth: 400,
      flexGrow: 1,
    },
  },
  webStepper: {
    root: {
      width: '100%',
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
}));

const questionsByParentName = keyBy(estimateQuestions, ((e) => e.name));
console.log({ questionsByParentName });

const defaultQuestions = [{
  id: 1,
  title: 'Which service are you interested in?',
  active: true,
  options: [{
    id: 1,
    title: 'Custom Software Development',
    subtitle: null,
    icon: customSoftwareLogo,
    iconAlt: 'person sitting next to laptop',
    selected: false,
    cost: 0,
  }, {
    id: 2,
    title: 'IOS/Android App Development',
    subtitle: null,
    icon: mobileDevelopmentLogo,
    iconAlt: 'person looking at mobile device',
    selected: false,
    cost: 0,
  }, {
    id: 3,
    title: 'Web App Development',
    subtitle: null,
    icon: webAppDevelopmentLogo,
    iconAlt: 'person looking at code block',
    selected: false,
    cost: 0,
  }],
}];

export function Estimate() {
  const classes = useStyles();
  const [selectedModules, setSelectedModules] = React.useState({});

  const handleCardClick = (id) => {
    setSelectedModules((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const handleEndForm = () => {
    console.log({ selectedModules });
  };

  const stepperProps = useStepper();

  return (
    (<Grid container direction="column" spacing={2}>
      <Grid item container justifyContent="center" spacing={2}>
        {estimateQuestions[stepperProps.activeStep]?.options.map((opt) => (
          <Grid item xl={3} lg={3} md={4} sm={4} xs={6} key={opt.id}>
            <SelectableCard {...opt} onClick={handleCardClick} selected={selectedModules[opt.id]} />
          </Grid>
        ))}
      </Grid>
      <Grid item>
        <AlternativeStepper steps={estimateQuestions.map((q) => q.name)} handleFinish={handleEndForm} {...stepperProps} />
        {/* <MobileStepper
              variant="progress"
              steps={6}
              position="static"
              activeStep={activeStep}
              className={classes.mobileStepper.root}
              nextButton={
                <Button size="small" onClick={handleNext} disabled={activeStep === 5}>
                  Next
                  {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                </Button>
              }
              backButton={
                <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                  {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                  Back
                </Button>
              }
            /> */}
      </Grid>
    </Grid>)
  );
}
